import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, Fragment } from 'react'

import { DatoGatsbyImage } from '@/components/DatoGatsbyImage'
import { LightboxLink } from '@/features/lightbox'
import { absoluteFill, bezier, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { useLeaderQuery } from './hooks/useLeaderQuery'

interface Props extends ComponentPropsWithoutRef<'a'> {
  data?: Queries.LeaderThumbnailFragment | null
  styleVariant?: 'DEFAULT' | 'HORIZONTAL'
}

export const LeaderThumbnail = ({
  data,
  styleVariant = 'DEFAULT',
  ...props
}: Props): JSX.Element => {
  const leader = useLeaderQuery(data?.slug)
  const styles = {
    link: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      color: inherit;
      text-decoration: none;
      text-align: center;
      ${styleVariant === 'HORIZONTAL' &&
      css`
        flex-direction: row;
        text-align: left;
        gap: 1em;
        max-width: fit-content;
      `}
    `,
    image: css`
      width: 100%;
      max-width: calc(1.333 * var(--fs-144));
      overflow: hidden;
      box-sizing: border-box;
      position: relative;
      border-radius: 50%;
      overflow: hidden;
      ${styleVariant === 'HORIZONTAL' &&
      css`
        max-width: var(--fs-108);
      `}
      &:after {
        content: '';
        ${absoluteFill}
        border-radius: 50%;
        border: 1px solid #00000011;
        box-sizing: border-box;
      }
      [data-gatsby-image-wrapper] {
        img {
          border-radius: 50%;
        }
        transition: transform 400ms ${bezier.easeOut};
      }
      @media (hover: hover) {
        a:hover > & {
          [data-gatsby-image-wrapper] {
            transform: scale3d(1.05, 1.05, 1);
          }
        }
      }
    `,
    name: css`
      font-family: var(--ff-body);
      font-size: var(--fs-24);
      font-weight: 450;
      margin: 0.5em 0;
      transition: color 300ms ease;
      ${styleVariant === 'HORIZONTAL' &&
      css`
        margin: 0 0 0.333em;
      `}
      ${mq().ms} {
        font-size: var(--fs-21);
      }
      @media (hover: hover) {
        a:hover & {
          color: ${colors.red};
        }
      }
    `,
    title: css`
      font-family: var(--ff-body);
      font-size: var(--fs-15);
      color: ${colors.gray45};
      /* text-transform: uppercase; */
      font-weight: 350;
      margin: 0;
      transition: color 300ms ease;
      @media (hover: hover) {
        a:hover > & {
          color: ${colors.red};
        }
      }
    `,
  }
  if (leader) {
    return (
      <LightboxLink
        data={leader}
        css={styles.link}
        {...props}
      >
        <div css={styles.image}>
          <DatoGatsbyImage
            image={leader?.headshot?.gatsbyImageData}
            alt={leader?.headshot?.alt || leader.name || ''}
          />
        </div>
        <div>
          <h3 css={styles.name}>{leader.name}</h3>
          <h4 css={styles.title}>{leader.title}</h4>
        </div>
      </LightboxLink>
    )
  } else {
    return <Fragment />
  }
}

export const LeaderThumbnailFragment = graphql`
  fragment LeaderThumbnail on DatoCmsLeader {
    __typename
    id: originalId
    slug
  }
`
